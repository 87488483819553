// middleware/redirects.ts


export default defineNuxtRouteMiddleware((to, from) => {
  const localePath = useLocalePath()
  // Redirect based on the current route path
  if (['/eternity-rings', '/eternity-rings/', '/in/eternity-rings', '/in/eternity-rings/'].includes(to.path)) {
    return navigateTo(localePath('/wedding-rings/eternity-rings'));
  }
  if (['/couple-rings', '/couple-rings/', '/in/couple-rings', '/in/couple-rings/'].includes(to.path)) {
    return navigateTo(localePath('/wedding-rings/couple-rings'));
  }
  if (['/stud-earrings', '/stud-earrings/', '/in/stud-earrings', '/in/stud-earrings/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/stud-earrings'));
  }
  if (['/hoop-earrings', '/hoop-earrings/', '/in/hoop-earrings', '/in/hoop-earrings/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/hoop-earrings'));
  }
  if (['/tennis-necklace', '/tennis-necklace/', '/in/tennis-necklace', '/in/tennis-necklace/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/tennis-necklaces'));
  }
  if (['/solitaire-necklace', '/solitaire-necklace/', '/in/solitaire-necklace', '/in/solitaire-necklace/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/solitaire-necklaces'));
  }
  if (['/tennis-bracelet', '/tennis-bracelet/', '/in/tennis-bracelet', '/in/tennis-bracelet/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/tennis-bracelets'));
  }
  if (['/bangle-bracelets', '/bangle-bracelets/', '/in/bangle-bracelets', '/in/bangle-bracelets/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/bangle-bracelets'));
  }
  if (['/fashion-bracelets', '/fashion-bracelets/', '/in/fashion-bracelets', '/in/fashion-bracelets/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/fashion-bracelets'));
  }
  if (['/fashion-earrings', '/fashion-earrings/', '/in/fashion-earrings', '/in/fashion-earrings/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/fashion-earrings'));
  }
  if (['/womens-wedding-rings', '/womens-wedding-rings/', '/in/womens-wedding-rings', '/in/womens-wedding-rings/'].includes(to.path)) {
    return navigateTo(localePath('/wedding-rings/womens-wedding-rings'));
  }
  if (['/anniversary-rings', '/anniversary-rings/', '/in/anniversary-rings', '/in/anniversary-rings/'].includes(to.path)) {
    return navigateTo(localePath('/wedding-rings/anniversary-rings'));
  }
  if (['/fashion-necklaces', '/fashion-necklaces/', '/in/fashion-necklaces', '/in/fashion-necklaces/'].includes(to.path)) {
    return navigateTo(localePath('/fine-jewelry/fashion-necklaces'));
  }

  if (['/colored-diamond-rings', '/colored-diamond-rings/'].includes(to.path)) {
    return navigateTo(localePath('/high-jewelry/colored-diamond-rings'));
  }
  if (['/in/colored-diamond-rings', '/in/colored-diamond-rings/'].includes(to.path)) {
    return navigateTo(localePath('/in/high-jewellery/colored-diamond-rings'));
  }

  
  if (['/soiree-colored-jewelry', '/soiree-colored-jewelry/'].includes(to.path)) {
    return navigateTo(localePath('/high-jewelry/soiree-colored-jewelry'));
  }
  if (['/in/soiree-colored-jewellery', '/in/soiree-colored-jewellery/'].includes(to.path)) {
    return navigateTo(localePath('/in/high-jewellery/soiree-colored-jewellery'));
  }

  if (['/engagement-ring', '/engagement-ring/', '/in/engagement-ring', '/in/engagement-ring/'].includes(to.path)) {
    return navigateTo(localePath('/engagement-rings'));
  }
  if (['/earrings', '/earrings/', '/in/earrings', '/in/earrings/'].includes(to.path)) {
    return navigateTo(localePath('/earring-settings'));
  }
  if (['/pendant', '/pendant/', '/in/pendant', '/in/pendant/'].includes(to.path)) {
    return navigateTo(localePath('/pendant-settings'));
  }
  if (['/blog/category', '/blog/category/'].includes(to.path)) {
    return navigateTo('/blog');
  }
  if (['/in/blog/category', '/in/blog/category/'].includes(to.path)) {
    return navigateTo('/in/blog');
  }
  if (['/ring-settings/10kt-white-gold-crowning-glory-diamond-ring-afdr1100-3001', '/ring-settings/10kt-white-gold-crowning-glory-diamond-ring-afdr1100-3001/'].includes(to.path)) {
    return navigateTo('/ring-settings');
  }
  if (['/wedding-band', '/wedding-band/'].includes(to.path)) {
    return navigateTo('/wedding-rings/womens-wedding-rings');
  }
  
  // Blog category old to new
  if (['/blog/new-trends', '/blog/new-trends/'].includes(to.path)) {
    return navigateTo('/blog/category/new-trends');
  }
  if (['/blog/category', '/blog/category/'].includes(to.path)) {
    return navigateTo('/blog');
  }
  if (['/blog/gift-guide', '/blog/gift-guide/'].includes(to.path)) {
    return navigateTo('/blog/category/gift-ideas');
  }
  if (['/blog/know-diamonds', '/blog/know-diamonds/'].includes(to.path)) {
    return navigateTo('/blog/category/diamond-guide');
  }
  if (['/blog/rings-and-wedding', '/blog/rings-and-wedding/'].includes(to.path)) { 
    return navigateTo('/blog/category/all-about-rings');
  }
   
  if (['/index.php', '/index.php/', '/index', '/index/'].includes(to.path)) {
    return navigateTo('/');
  }
  
  // Additional redirects
  if ('/in/fine-jewelry' === to.path) {
    return navigateTo('/in/fine-jewellery');
  }
  if ('/in/fine-jewelry/stud-earrings' === to.path) {
    return navigateTo('/in/fine-jewellery/stud-earrings');
  }
  if ('/in/fine-jewelry/hoop-earrings' === to.path) {
    return navigateTo('/in/fine-jewellery/hoop-earrings');
  }
  if ('/in/fine-jewelry/tennis-necklaces' === to.path) {
    return navigateTo('/in/fine-jewellery/tennis-necklaces');
  }
  if ('/in/fine-jewelry/fashion-necklaces' === to.path) {
    return navigateTo('/in/fine-jewellery/fashion-necklaces');
  }
  if ('/in/fine-jewelry/solitaire-necklaces' === to.path) {
    return navigateTo('/in/fine-jewellery/solitaire-necklaces');
  }
  if ('/in/fine-jewelry/tennis-bracelets' === to.path) {
    return navigateTo('/in/fine-jewellery/tennis-bracelets');
  }
  if ('/in/fine-jewelry/bangle-bracelets' === to.path) {
    return navigateTo('/in/fine-jewellery/bangle-bracelets');
  }
  if ('/in/fine-jewelry/fashion-bracelets' === to.path) {
    return navigateTo('/in/fine-jewellery/fashion-bracelets');
  }
  if ('/in/fine-jewelry/fashion-earrings' === to.path) {
    return navigateTo('/in/fine-jewellery/fashion-earrings');
  }

  if ('/in/high-jewelry/colored-diamond-rings' === to.path) {
    return navigateTo('/in/high-jewellery/colored-diamond-rings');
  }
  if ('/in/high-jewellery/soiree-colored-jewelry' === to.path) {
    return navigateTo('/in/high-jewellery/soiree-colored-jewellery');
  }
  if ('/in/high-jewelry/soiree-colored-jewellery' === to.path) {
    return navigateTo('/in/high-jewellery/soiree-colored-jewellery');
  }
  if ('/in/high-jewelry/soiree-colored-jewelry' === to.path) {
    return navigateTo('/in/high-jewellery/soiree-colored-jewellery');
  }


  if ('/in/diamond-jewelry' === to.path) {
    return navigateTo('/in/diamond-jewellery');
  }
  if ('/in/customized-jewelry' === to.path) {
    return navigateTo('/in/customise-jewellery');
  }
  


  if ('/fine-jewellery' === to.path) {
    return navigateTo('/fine-jewelry');
  }
  if ('/fine-jewellery/stud-earrings' === to.path) {
    return navigateTo('/fine-jewelry/stud-earrings');
  }
  if ('/fine-jewellery/hoop-earrings' === to.path) {
    return navigateTo('/fine-jewelry/hoop-earrings');
  }
  if ('/fine-jewellery/tennis-necklaces' === to.path) {
    return navigateTo('/fine-jewelry/tennis-necklaces');
  }
  if ('/fine-jewellery/fashion-necklaces' === to.path) {
    return navigateTo('/fine-jewelry/fashion-necklaces');
  }
  if ('/fine-jewellery/solitaire-necklaces' === to.path) {
    return navigateTo('/fine-jewelry/solitaire-necklaces');
  }
  if ('/fine-jewellery/tennis-bracelets' === to.path) {
    return navigateTo('/fine-jewelry/tennis-bracelets');
  }
  if ('/fine-jewellery/bangle-bracelets' === to.path) {
    return navigateTo('/fine-jewelry/bangle-bracelets');
  }
  if ('/fine-jewellery/fashion-bracelets' === to.path) {
    return navigateTo('/fine-jewelry/fashion-bracelets');
  }
  if ('/high-jewellery/colored-diamond-rings' === to.path) {
    return navigateTo('/high-jewelry/colored-diamond-rings');
  }
  if ('/high-jewellery/colored-diamond-rings' === to.path) {
    return navigateTo('/high-jewelry/colored-diamond-rings');
  }
  if ('/high-jewellery/soiree-colored-jewelry' === to.path) {
    return navigateTo('/high-jewelry/soiree-colored-jewelry');
  }
  if ('/high-jewellery/soiree-colored-jewellery' === to.path) {
    return navigateTo('/high-jewelry/soiree-colored-jewelry');
  }
  if ('/high-jewelry/soiree-colored-jewellery' === to.path) {
    return navigateTo('/high-jewelry/soiree-colored-jewelry');
  }

  



  if ('/diamond-jewellery' === to.path) {
    return navigateTo('/diamond-jewelry');
  }
  if ('/customise-jewellery' === to.path) {
    return navigateTo('/customized-jewelry');
  }
  // /in/sitemap-preset.xml



// if('/lab-diamond-rings/round-cut' === to.path){
//   return navigateTo('/loose-diamonds/round-cut-diamond')
// }

if('/loose-diamonds/oval-cut' === to.path){
  return navigateTo('/loose-diamonds/oval-cut-diamond')
}

if('/loose-diamonds/pear-cut' === to.path){
  return navigateTo('/loose-diamonds/pear-cut-diamond')
}

if('/loose-diamonds/emerald-cut' === to.path){
  return navigateTo('/loose-diamonds/emerald-cut-diamond')
}

if('/loose-diamonds/cushion-cut' === to.path){
  return navigateTo('/loose-diamonds/cushion-cut-diamond')
}

if('/loose-diamonds/princess-cut' === to.path){
  return navigateTo('/loose-diamonds/princess-cut-diamond')
}

if('/loose-diamonds/radiant-cut' === to.path){
  return navigateTo('/loose-diamonds/radiant-cut-diamond')
}

if('/loose-diamonds/heart-cut' === to.path){
  return navigateTo('/loose-diamonds/heart-cut-diamond')
}

if('/loose-diamonds/asscher-cut' === to.path){
  return navigateTo('/loose-diamonds/asscher-cut-diamond')
}

if('/loose-diamonds/marquise-cut' === to.path){
  return navigateTo('/loose-diamonds/marquise-cut-diamond')
}

if('/loose-diamonds/heart-shaped-diamond' === to.path){
  return navigateTo('/loose-diamonds/heart-cut-diamond')
}
	
	
if('/lab-diamond-rings/round-cut' === to.path){
  return navigateTo('/lab-diamond-rings/round-rings')
}

if('/lab-diamond-rings/emerald-cut' === to.path){
  return navigateTo('/lab-diamond-rings/emerald-rings')
}

if('/lab-diamond-rings/oval-cut' === to.path){
  return navigateTo('/lab-diamond-rings/oval-rings')
}

if('/lab-diamond-rings/pear-cut' === to.path){
  return navigateTo('/lab-diamond-rings/pear-rings')
}

if('/lab-diamond-rings/cushion-cut' === to.path){
  return navigateTo('/lab-diamond-rings/cushion-rings')
}

if('/lab-diamond-rings/princess-cut' === to.path){
  return navigateTo('/lab-diamond-rings/princess-rings')
}

if('/lab-diamond-rings/radiant-cut' === to.path){
  return navigateTo('/lab-diamond-rings/radiant-rings')
}

if('/lab-diamond-rings/heart-cut' === to.path){
  return navigateTo('/lab-diamond-rings/heart-rings')
}

if('/lab-diamond-rings/asscher-cut' === to.path){
  return navigateTo('/lab-diamond-rings/asscher-rings')
}

if('/lab-diamond-rings/marquise-cut' === to.path){
  return navigateTo('/lab-diamond-rings/marquise-rings')
}

if('/engagement-ring-settings' === to.path){
  return navigateTo('/ring-settings')
}

if('/engagement-ring-settings/white-gold' === to.path){
  return navigateTo('/ring-settings/white-gold-engagement-rings')
}

if('/engagement-ring-settings/yellow-gold' === to.path){
  return navigateTo('/ring-settings/yellow-gold-engagement-rings')
}

if('/engagement-ring-settings/rose-gold' === to.path){
  return navigateTo('/ring-settings/rose-gold-engagement-rings')
}

if('/engagement-ring-settings/solitaire' === to.path){
  return navigateTo('/ring-settings/solitaire-engagement-rings')
}

if('/engagement-ring-settings/three-stone' === to.path){
  return navigateTo('/ring-settings/three-stone-engagement-rings')
}

if('/engagement-ring-settings/side-stone' === to.path){
  return navigateTo('/ring-settings/side-stone-engagement-rings')
}

if('/engagement-ring-settings/halo' === to.path){
  return navigateTo('/ring-settings/halo-engagement-rings')
}

if('/engagement-ring-settings/vintage' === to.path){
  return navigateTo('/ring-settings/vintage-engagement-rings')
}

if('/customize-your-jewelry' === to.path){
  return navigateTo('/customized-jewelry')
}
 
if('/size-guide' === to.path){
  return navigateTo('/how-to-find-ring-size')
}

if('/shop/eternity-rings' === to.path){
  return navigateTo('/wedding-rings/eternity-rings')
}

if('/shop/couple-rings' === to.path){
  return navigateTo('/wedding-rings/couple-rings')
}

if('/shop/stud-earrings' === to.path){
  return navigateTo('/fine-jewelry/stud-earrings')
}


if('/shop/tennis-bracelet' === to.path){
  return navigateTo('/fine-jewelry/tennis-bracelets')
}

if('/shop/bangle-bracelet' === to.path){
  return navigateTo('/fine-jewelry/bangle-bracelets')
}

if('/lab-diamonds/round-cut' === to.path){
  return navigateTo('/loose-diamonds/round-cut-diamond')
}

if('/lab-diamonds/oval-cut' === to.path){
  return navigateTo('/loose-diamonds/oval-cut-diamond')
}

if('/lab-diamonds/pear-cut' === to.path){
  return navigateTo('/loose-diamonds/pear-cut-diamond')
}

if('/lab-diamonds/cushion-cut' === to.path){
  return navigateTo('/loose-diamonds/cushion-cut-diamond')
}

if('/lab-diamonds/princess-cut' === to.path){
  return navigateTo('/loose-diamonds/princess-cut-diamond')
}

if('/lab-diamonds/radiant-cut' === to.path){
  return navigateTo('/loose-diamonds/radiant-cut-diamond')
}

if('/lab-diamonds/emerald-cut' === to.path){
  return navigateTo('/loose-diamonds/emerald-cut-diamond')
}

if('/lab-diamonds/heart-cut' === to.path){
  return navigateTo('/loose-diamonds/heart-shaped-diamond')
}

if('/lab-diamonds/asscher-cut' === to.path){
  return navigateTo('/loose-diamonds/asscher-cut-diamond')
}

if('/lab-diamonds/marquise-cut' === to.path){
  return navigateTo('/loose-diamonds/marquise-cut-diamond')
}

if('/why-friendly/exchange-policy' === to.path){
  return navigateTo('/customer-service')
}

if('/conflict-free-diamonds' === to.path){
  return navigateTo('/ethical-and-conflict-free-diamonds')
}

if('/why-friendly/shipping-policy' === to.path){
  return navigateTo('/customer-service/shipping-policy')
}

if('/why-friendly/return-policy' === to.path){
  return navigateTo('/customer-service/return-policy')
}

if('/why-friendly/resizing' === to.path){
  return navigateTo('/customer-service/ring-resizing')
}

if('/why-friendly/life-time-warranty' === to.path){
  return navigateTo('/customer-service/life-time-warranty')
}

if('/why-friendly/engraving' === to.path){
  return navigateTo('/customer-service/ring-engraving')
}

if('/why-friendly/payment-options' === to.path){
  return navigateTo('/customer-service/payment-options')
}

if('/blood-diamond-facts' === to.path){
  return navigateTo('/ethical-and-conflict-free-diamonds')
}

if('/diamond-anatomy' === to.path){
  return navigateTo('/4-cs-diamond')
}

if('/customer-values' === to.path){
  return navigateTo('/customer-service')
}

if('/ethical-edge' === to.path){
  return navigateTo('/ethical-and-conflict-free-diamonds')
}

if('/market-need' === to.path){
  return navigateTo('/ethical-and-conflict-free-diamonds')
}

if('/testimonial/share-your-moments' === to.path){
  return navigateTo('/testimonials')
}

if('/learn-about-lab-made-diamonds' === to.path){
  return navigateTo('/lab-diamonds')
}

if('/shipping-and-vat' === to.path){
  return navigateTo('/customer-service/shipping-policy')
}

if('/returns-exchanges-warranty' === to.path){
  return navigateTo('/customer-service/return-policy')
}

if('/engagement-ring-settings/rose' === to.path){
  return navigateTo('/ring-settings/rose-gold-engagement-rings')
}

if('/engagement-ring-settings/yellow' === to.path){
  return navigateTo('/ring-settings/yellow-gold-engagement-rings')
}

if('/engagement-ring-settings/white' === to.path){
  return navigateTo('/ring-settings/white-gold-engagement-rings')
}

 
  const pathArray = [
    // usa
    '/sitemap-setting.xml', '/sitemap-blog.xml', '/sitemap-preset.xml','/sitemap-loose1.xml', '/sitemap-loose2.xml',
    '/sitemap-loose3.xml', '/sitemap-loose4.xml', '/sitemap-loose5.xml', '/sitemap-loose6.xml', '/sitemap-loose7.xml', '/sitemap-loose8.xml',
    // in
    '/in/sitemap-setting.xml', '/in/sitemap-blog.xml', '/in/sitemap-preset.xml','/in/sitemap-loose1.xml', '/in/sitemap-loose2.xml',
    '/in/sitemap-loose3.xml', '/in/sitemap-loose4.xml', '/in/sitemap-loose5.xml', '/in/sitemap-loose6.xml', '/in/sitemap-loose7.xml', '/in/sitemap-loose8.xml'
  ];


  if (pathArray.includes( to.path )) {

    const externalPath = 'https://backend.friendlydiamonds.com'
    return navigateTo({ path: `${externalPath}${to.path}` }, { redirectCode: 301, external:true });
  }

});
