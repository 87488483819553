import { default as indexsTyW8uIr0AMeta } from "/opt/buildhome/repo/pages/[slug]-carat-diamond-rings/index.vue?macro=true";
import { default as indexIMDB9oANQrMeta } from "/opt/buildhome/repo/pages/[slug]-engagement-rings/index.vue?macro=true";
import { default as index0l1oyleYyVMeta } from "/opt/buildhome/repo/pages/[type]/[slug]/index.vue?macro=true";
import { default as indexTtEMPWEZhEMeta } from "/opt/buildhome/repo/pages/[type]/index.vue?macro=true";
import { default as _4_45cs_45diamondTldLKUgcPEMeta } from "/opt/buildhome/repo/pages/4-cs-diamond.vue?macro=true";
import { default as about_45usfO0GZVI16CMeta } from "/opt/buildhome/repo/pages/about-us.vue?macro=true";
import { default as change_45password25pXFeMibUMeta } from "/opt/buildhome/repo/pages/account/change-password.vue?macro=true";
import { default as detailE4PeCUyVC3Meta } from "/opt/buildhome/repo/pages/account/detail.vue?macro=true";
import { default as _91id_93DlpXRTT1ZQMeta } from "/opt/buildhome/repo/pages/account/orders/[id].vue?macro=true";
import { default as indexrQAP8wwvotMeta } from "/opt/buildhome/repo/pages/account/orders/index.vue?macro=true";
import { default as appointmentMmgx5Dor93Meta } from "/opt/buildhome/repo/pages/appointment.vue?macro=true";
import { default as _91_91sub_type_93_93lBRen6zQm6Meta } from "/opt/buildhome/repo/pages/blog/[[type]]/[[sub_type]].vue?macro=true";
import { default as _91slug_93x8nwzxMykbMeta } from "/opt/buildhome/repo/pages/blog/[slug].vue?macro=true";
import { default as cartltOYTvmzvgMeta } from "/opt/buildhome/repo/pages/cart.vue?macro=true";
import { default as addressyYFdMNea5cMeta } from "/opt/buildhome/repo/pages/checkout/address.vue?macro=true";
import { default as paymentaclhGX8KoBMeta } from "/opt/buildhome/repo/pages/checkout/payment.vue?macro=true";
import { default as thank_45youF7vTlVE2c2Meta } from "/opt/buildhome/repo/pages/checkout/thank-you.vue?macro=true";
import { default as contact_45usJotZaqxjzYMeta } from "/opt/buildhome/repo/pages/contact-us.vue?macro=true";
import { default as cookie_45policyR0Q3gHg5HMMeta } from "/opt/buildhome/repo/pages/cookie-policy.vue?macro=true";
import { default as buyback_45policycrc8HBhpzmMeta } from "/opt/buildhome/repo/pages/customer-service/buyback-policy.vue?macro=true";
import { default as exchange_45policyiRpSCw4o8hMeta } from "/opt/buildhome/repo/pages/customer-service/exchange-policy.vue?macro=true";
import { default as indexjVBCZnMGzqMeta } from "/opt/buildhome/repo/pages/customer-service/index.vue?macro=true";
import { default as life_45time_45warrantyydWqr0gEHHMeta } from "/opt/buildhome/repo/pages/customer-service/life-time-warranty.vue?macro=true";
import { default as payment_45optionsSUgSbI9Jx6Meta } from "/opt/buildhome/repo/pages/customer-service/payment-options.vue?macro=true";
import { default as return_45policyMIAV6GOWwHMeta } from "/opt/buildhome/repo/pages/customer-service/return-policy.vue?macro=true";
import { default as ring_45engravingdON7edDOdrMeta } from "/opt/buildhome/repo/pages/customer-service/ring-engraving.vue?macro=true";
import { default as ring_45resizingzLahRjXmVZMeta } from "/opt/buildhome/repo/pages/customer-service/ring-resizing.vue?macro=true";
import { default as shipping_45policy0N2sYjAOLsMeta } from "/opt/buildhome/repo/pages/customer-service/shipping-policy.vue?macro=true";
import { default as customized_45jewelryWdk5Y12VO9Meta } from "/opt/buildhome/repo/pages/customized-jewelry.vue?macro=true";
import { default as indexlgrFVA7IwyMeta } from "/opt/buildhome/repo/pages/diamond-jewelry/[[start_with]]/index.vue?macro=true";
import { default as _91_91start_with_93_93Jp7r7xDepbMeta } from "/opt/buildhome/repo/pages/earring-settings/[[start_with]].vue?macro=true";
import { default as indexaIUetqf6MdMeta } from "/opt/buildhome/repo/pages/earring-settings/[slug]/index.vue?macro=true";
import { default as completeWH0nAi6to7Meta } from "/opt/buildhome/repo/pages/earrings/complete.vue?macro=true";
import { default as engagement_45ring_45for_45women5TOxsekvq8Meta } from "/opt/buildhome/repo/pages/engagement-ring-for-women.vue?macro=true";
import { default as complete_45the_45ringLQrkYMYk2SMeta } from "/opt/buildhome/repo/pages/engagement-ring-guide/complete-the-ring.vue?macro=true";
import { default as indexacYsDy1jO3Meta } from "/opt/buildhome/repo/pages/engagement-ring-guide/index.vue?macro=true";
import { default as ring_45cost4QpkhAnJyXMeta } from "/opt/buildhome/repo/pages/engagement-ring-guide/ring-cost.vue?macro=true";
import { default as time_45to_45proposeFyQTnVHnotMeta } from "/opt/buildhome/repo/pages/engagement-ring-guide/time-to-propose.vue?macro=true";
import { default as types_45of_45diamondsxIqgyzCi2VMeta } from "/opt/buildhome/repo/pages/engagement-ring-guide/types-of-diamonds.vue?macro=true";
import { default as types_45of_45ring_45settingsoJ3D6jBfQ0Meta } from "/opt/buildhome/repo/pages/engagement-ring-guide/types-of-ring-settings.vue?macro=true";
import { default as completeTU55VOp2ipMeta } from "/opt/buildhome/repo/pages/engagement-ring/complete.vue?macro=true";
import { default as engagement_45ringsPsc7tpxrciMeta } from "/opt/buildhome/repo/pages/engagement-rings.vue?macro=true";
import { default as ethical_45and_45conflict_45free_45diamonds1Ig4l8wPWpMeta } from "/opt/buildhome/repo/pages/ethical-and-conflict-free-diamonds.vue?macro=true";
import { default as fine_45jewelry_45newmZWqHlQcoPMeta } from "/opt/buildhome/repo/pages/fine-jewelry-new.vue?macro=true";
import { default as bangle_45braceletspkaAX9mFLGMeta } from "/opt/buildhome/repo/pages/fine-jewelry/bangle-bracelets.vue?macro=true";
import { default as fashion_45braceletsdPH5NqfcLsMeta } from "/opt/buildhome/repo/pages/fine-jewelry/fashion-bracelets.vue?macro=true";
import { default as fashion_45earringsSzmOBXT1uNMeta } from "/opt/buildhome/repo/pages/fine-jewelry/fashion-earrings.vue?macro=true";
import { default as fashion_45necklacesh6fF0osaMrMeta } from "/opt/buildhome/repo/pages/fine-jewelry/fashion-necklaces.vue?macro=true";
import { default as hoop_45earringsMJndSuHzutMeta } from "/opt/buildhome/repo/pages/fine-jewelry/hoop-earrings.vue?macro=true";
import { default as indexSmzOzjvk0SMeta } from "/opt/buildhome/repo/pages/fine-jewelry/index.vue?macro=true";
import { default as solitaire_45necklacesMlxJfymIi1Meta } from "/opt/buildhome/repo/pages/fine-jewelry/solitaire-necklaces.vue?macro=true";
import { default as stud_45earrings3ldcaDLOh7Meta } from "/opt/buildhome/repo/pages/fine-jewelry/stud-earrings.vue?macro=true";
import { default as tennis_45braceletsFVtShn9KEsMeta } from "/opt/buildhome/repo/pages/fine-jewelry/tennis-bracelets.vue?macro=true";
import { default as tennis_45necklaces6u7o4Lt4cLMeta } from "/opt/buildhome/repo/pages/fine-jewelry/tennis-necklaces.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as franchisevnnmWPzFD4Meta } from "/opt/buildhome/repo/pages/franchise.vue?macro=true";
import { default as free_45productbRJx4Kbc0nMeta } from "/opt/buildhome/repo/pages/free-product.vue?macro=true";
import { default as friendly_45diamond_45difference4nRZZrDvp8Meta } from "/opt/buildhome/repo/pages/friendly-diamond-difference.vue?macro=true";
import { default as green_45and_45sustainable_45giftkzgANt3EWcMeta } from "/opt/buildhome/repo/pages/green-and-sustainable-gift.vue?macro=true";
import { default as colored_45diamond_45rings7Cig1NUhF4Meta } from "/opt/buildhome/repo/pages/high-jewelry/colored-diamond-rings.vue?macro=true";
import { default as hues_45ringsZj9KjhAS8LMeta } from "/opt/buildhome/repo/pages/high-jewelry/hues-rings.vue?macro=true";
import { default as indexcQFY4ZV22IMeta } from "/opt/buildhome/repo/pages/high-jewelry/index.vue?macro=true";
import { default as soiree_45colored_45jewelryyC96xpdhO3Meta } from "/opt/buildhome/repo/pages/high-jewelry/soiree-colored-jewelry.vue?macro=true";
import { default as how_45to_45find_45ring_45sizeougwxDXFZSMeta } from "/opt/buildhome/repo/pages/how-to-find-ring-size.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexLtJLE6PbXQMeta } from "/opt/buildhome/repo/pages/inspiration-[slug]/index.vue?macro=true";
import { default as _91_91start_with_93_93q9gncYjmWDMeta } from "/opt/buildhome/repo/pages/lab-diamond-earrings/[[start_with]].vue?macro=true";
import { default as indexmBvYl92OaPMeta } from "/opt/buildhome/repo/pages/lab-diamond-earrings/[slug]/index.vue?macro=true";
import { default as _91_91start_with_93_93KX5nKruSGiMeta } from "/opt/buildhome/repo/pages/lab-diamond-pendants/[[start_with]].vue?macro=true";
import { default as indexhIkRGQCSFdMeta } from "/opt/buildhome/repo/pages/lab-diamond-pendants/[slug]/index.vue?macro=true";
import { default as _91_91start_with_93_93QY1lBgvY0zMeta } from "/opt/buildhome/repo/pages/lab-diamond-rings/[[start_with]].vue?macro=true";
import { default as indexfrE9jM0Z4OMeta } from "/opt/buildhome/repo/pages/lab-diamond-rings/[slug]/index.vue?macro=true";
import { default as lab_45diamondsSo8d50d4PJMeta } from "/opt/buildhome/repo/pages/lab-diamonds.vue?macro=true";
import { default as light_45performance_45reportVsSJJ8zmbiMeta } from "/opt/buildhome/repo/pages/light-performance-report.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _91_91start_with_93_936CKwf7RQbbMeta } from "/opt/buildhome/repo/pages/loose-diamonds/[[start_with]].vue?macro=true";
import { default as indexgsuu6onbmCMeta } from "/opt/buildhome/repo/pages/loose-diamonds/[slug]/index.vue?macro=true";
import { default as our_45share_45of_45carenXp9181YSuMeta } from "/opt/buildhome/repo/pages/our-share-of-care.vue?macro=true";
import { default as our_45storyk3A0pMQRqwMeta } from "/opt/buildhome/repo/pages/our-story.vue?macro=true";
import { default as _91_91start_with_93_939QEojIU0fzMeta } from "/opt/buildhome/repo/pages/pendant-settings/[[start_with]].vue?macro=true";
import { default as indexMVtt49dTuqMeta } from "/opt/buildhome/repo/pages/pendant-settings/[slug]/index.vue?macro=true";
import { default as completeyAtcFsEG59Meta } from "/opt/buildhome/repo/pages/pendant/complete.vue?macro=true";
import { default as indexke4KzZBi7xMeta } from "/opt/buildhome/repo/pages/preset-engagement-rings/[slug]/index.vue?macro=true";
import { default as indexOUAfysWqiIMeta } from "/opt/buildhome/repo/pages/preset-engagement-rings/index.vue?macro=true";
import { default as pressHctaTRD9jaMeta } from "/opt/buildhome/repo/pages/press.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as index1NnWFUKxbrMeta } from "/opt/buildhome/repo/pages/ready-to-ship-earrings/index.vue?macro=true";
import { default as index05bnLK7ZwuMeta } from "/opt/buildhome/repo/pages/ready-to-ship-engagement-rings/index.vue?macro=true";
import { default as indexdreOrUYBCpMeta } from "/opt/buildhome/repo/pages/ready-to-ship-pendants/index.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as _91_91start_with_93_93IYcmiW5ASAMeta } from "/opt/buildhome/repo/pages/ring-settings/[[start_with]].vue?macro=true";
import { default as indexspMl1WkpyRMeta } from "/opt/buildhome/repo/pages/ring-settings/[slug]/index.vue?macro=true";
import { default as searchW2JMgpGXtYMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as sitemap04Ic8oom6WMeta } from "/opt/buildhome/repo/pages/sitemap.vue?macro=true";
import { default as terms_45of_45service4Xad5aMSOFMeta } from "/opt/buildhome/repo/pages/terms-of-service.vue?macro=true";
import { default as indexrC1hADaOsGMeta } from "/opt/buildhome/repo/pages/testimonials/[slug]/index.vue?macro=true";
import { default as indexslhJXy2WR9Meta } from "/opt/buildhome/repo/pages/testimonials/index.vue?macro=true";
import { default as indexshj6ffwxrNMeta } from "/opt/buildhome/repo/pages/toi-et-moi-engagement-rings/index.vue?macro=true";
import { default as valentines_45day_45jewelryuYlb1yZkw9Meta } from "/opt/buildhome/repo/pages/valentines-day-jewelry.vue?macro=true";
import { default as anniversary_45ringsJcAwwr740cMeta } from "/opt/buildhome/repo/pages/wedding-rings/anniversary-rings.vue?macro=true";
import { default as couple_45ringsyEeQd9ciVSMeta } from "/opt/buildhome/repo/pages/wedding-rings/couple-rings.vue?macro=true";
import { default as eternity_45ringsBYVqspJJxGMeta } from "/opt/buildhome/repo/pages/wedding-rings/eternity-rings.vue?macro=true";
import { default as indexUQoAeaLy88Meta } from "/opt/buildhome/repo/pages/wedding-rings/index.vue?macro=true";
import { default as jewelry_45engagement_45ringskW1jwE1gDhMeta } from "/opt/buildhome/repo/pages/wedding-rings/jewelry-engagement-rings.vue?macro=true";
import { default as womens_45wedding_45ringsxLSSjPEdeTMeta } from "/opt/buildhome/repo/pages/wedding-rings/womens-wedding-rings.vue?macro=true";
export default [
  {
    name: "recently-purchase-carat___us",
    path: "/:slug()-carat-diamond-rings",
    meta: indexsTyW8uIr0AMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[slug]-carat-diamond-rings/index.vue")
  },
  {
    name: "recently-purchase-carat___in",
    path: "/in/:slug()-carat-diamond-rings",
    meta: indexsTyW8uIr0AMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[slug]-carat-diamond-rings/index.vue")
  },
  {
    name: "recently-purchase-shape___us",
    path: "/:slug()-engagement-rings",
    meta: indexIMDB9oANQrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[slug]-engagement-rings/index.vue")
  },
  {
    name: "recently-purchase-shape___in",
    path: "/in/:slug()-engagement-rings",
    meta: indexIMDB9oANQrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[slug]-engagement-rings/index.vue")
  },
  {
    name: "shop-detail___us",
    path: "/:type()/:slug()",
    meta: index0l1oyleYyVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[type]/[slug]/index.vue")
  },
  {
    name: "shop-detail___in",
    path: "/in/:type()/:slug()",
    meta: index0l1oyleYyVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[type]/[slug]/index.vue")
  },
  {
    name: "type___us",
    path: "/:type()",
    meta: indexTtEMPWEZhEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[type]/index.vue")
  },
  {
    name: "type___in",
    path: "/in/:type()",
    meta: indexTtEMPWEZhEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[type]/index.vue")
  },
  {
    name: "4-cs-diamond___us",
    path: "/4-cs-diamond",
    component: () => import("/opt/buildhome/repo/pages/4-cs-diamond.vue")
  },
  {
    name: "4-cs-diamond___in",
    path: "/in/4-cs-diamond",
    component: () => import("/opt/buildhome/repo/pages/4-cs-diamond.vue")
  },
  {
    name: "about-us___us",
    path: "/about-us",
    component: () => import("/opt/buildhome/repo/pages/about-us.vue")
  },
  {
    name: "about-us___in",
    path: "/in/about-us",
    component: () => import("/opt/buildhome/repo/pages/about-us.vue")
  },
  {
    name: "account-change-password___us",
    path: "/account/change-password",
    meta: change_45password25pXFeMibUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/change-password.vue")
  },
  {
    name: "account-change-password___in",
    path: "/in/account/change-password",
    meta: change_45password25pXFeMibUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/change-password.vue")
  },
  {
    name: "account-detail___us",
    path: "/account/detail",
    meta: detailE4PeCUyVC3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/account/detail.vue")
  },
  {
    name: "account-detail___in",
    path: "/in/account/detail",
    meta: detailE4PeCUyVC3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/account/detail.vue")
  },
  {
    name: "account-orders-id___us",
    path: "/account/orders/:id()",
    meta: _91id_93DlpXRTT1ZQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-id___in",
    path: "/in/account/orders/:id()",
    meta: _91id_93DlpXRTT1ZQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders___us",
    path: "/account/orders",
    meta: indexrQAP8wwvotMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/orders/index.vue")
  },
  {
    name: "account-orders___in",
    path: "/in/account/orders",
    meta: indexrQAP8wwvotMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/orders/index.vue")
  },
  {
    name: "appointment___us",
    path: "/appointment",
    component: () => import("/opt/buildhome/repo/pages/appointment.vue")
  },
  {
    name: "appointment___in",
    path: "/in/appointment",
    component: () => import("/opt/buildhome/repo/pages/appointment.vue")
  },
  {
    name: "blog-listing___us",
    path: "/blog/:type?/:sub_type?",
    meta: _91_91sub_type_93_93lBRen6zQm6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/[[type]]/[[sub_type]].vue")
  },
  {
    name: "blog-listing___in",
    path: "/in/blog/:type?/:sub_type?",
    meta: _91_91sub_type_93_93lBRen6zQm6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/[[type]]/[[sub_type]].vue")
  },
  {
    name: "blog-detail___us",
    path: "/blog/:slug()",
    meta: _91slug_93x8nwzxMykbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue")
  },
  {
    name: "blog-detail___in",
    path: "/in/blog/:slug()",
    meta: _91slug_93x8nwzxMykbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue")
  },
  {
    name: "cart___us",
    path: "/cart",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "cart___in",
    path: "/in/cart",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "checkout-address___us",
    path: "/checkout/address",
    component: () => import("/opt/buildhome/repo/pages/checkout/address.vue")
  },
  {
    name: "checkout-address___in",
    path: "/in/checkout/address",
    component: () => import("/opt/buildhome/repo/pages/checkout/address.vue")
  },
  {
    name: "checkout-payment___us",
    path: "/checkout/payment",
    component: () => import("/opt/buildhome/repo/pages/checkout/payment.vue")
  },
  {
    name: "checkout-payment___in",
    path: "/in/checkout/payment",
    component: () => import("/opt/buildhome/repo/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___us",
    path: "/checkout/thank-you",
    component: () => import("/opt/buildhome/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "checkout-thank-you___in",
    path: "/in/checkout/thank-you",
    component: () => import("/opt/buildhome/repo/pages/checkout/thank-you.vue")
  },
  {
    name: "contact-us___us",
    path: "/contact-us",
    component: () => import("/opt/buildhome/repo/pages/contact-us.vue")
  },
  {
    name: "contact-us___in",
    path: "/in/contact-us",
    component: () => import("/opt/buildhome/repo/pages/contact-us.vue")
  },
  {
    name: "cookie-policy___us",
    path: "/cookie-policy",
    component: () => import("/opt/buildhome/repo/pages/cookie-policy.vue")
  },
  {
    name: "cookie-policy___in",
    path: "/in/cookie-policy",
    component: () => import("/opt/buildhome/repo/pages/cookie-policy.vue")
  },
  {
    name: "customer-service-buyback-policy___us",
    path: "/customer-service/buyback-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/buyback-policy.vue")
  },
  {
    name: "customer-service-buyback-policy___in",
    path: "/in/customer-service/buyback-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/buyback-policy.vue")
  },
  {
    name: "customer-service-exchange-policy___us",
    path: "/customer-service/exchange-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/exchange-policy.vue")
  },
  {
    name: "customer-service-exchange-policy___in",
    path: "/in/customer-service/exchange-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/exchange-policy.vue")
  },
  {
    name: "customer-service___us",
    path: "/customer-service",
    component: () => import("/opt/buildhome/repo/pages/customer-service/index.vue")
  },
  {
    name: "customer-service___in",
    path: "/in/customer-service",
    component: () => import("/opt/buildhome/repo/pages/customer-service/index.vue")
  },
  {
    name: "customer-service-life-time-warranty___us",
    path: "/customer-service/life-time-warranty",
    component: () => import("/opt/buildhome/repo/pages/customer-service/life-time-warranty.vue")
  },
  {
    name: "customer-service-life-time-warranty___in",
    path: "/in/customer-service/life-time-warranty",
    component: () => import("/opt/buildhome/repo/pages/customer-service/life-time-warranty.vue")
  },
  {
    name: "customer-service-payment-options___us",
    path: "/customer-service/payment-options",
    component: () => import("/opt/buildhome/repo/pages/customer-service/payment-options.vue")
  },
  {
    name: "customer-service-payment-options___in",
    path: "/in/customer-service/payment-options",
    component: () => import("/opt/buildhome/repo/pages/customer-service/payment-options.vue")
  },
  {
    name: "customer-service-return-policy___us",
    path: "/customer-service/return-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/return-policy.vue")
  },
  {
    name: "customer-service-return-policy___in",
    path: "/in/customer-service/return-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/return-policy.vue")
  },
  {
    name: "customer-service-ring-engraving___us",
    path: "/customer-service/ring-engraving",
    component: () => import("/opt/buildhome/repo/pages/customer-service/ring-engraving.vue")
  },
  {
    name: "customer-service-ring-engraving___in",
    path: "/in/customer-service/ring-engraving",
    component: () => import("/opt/buildhome/repo/pages/customer-service/ring-engraving.vue")
  },
  {
    name: "customer-service-ring-resizing___us",
    path: "/customer-service/ring-resizing",
    component: () => import("/opt/buildhome/repo/pages/customer-service/ring-resizing.vue")
  },
  {
    name: "customer-service-ring-resizing___in",
    path: "/in/customer-service/ring-resizing",
    component: () => import("/opt/buildhome/repo/pages/customer-service/ring-resizing.vue")
  },
  {
    name: "customer-service-shipping-policy___us",
    path: "/customer-service/shipping-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/shipping-policy.vue")
  },
  {
    name: "customer-service-shipping-policy___in",
    path: "/in/customer-service/shipping-policy",
    component: () => import("/opt/buildhome/repo/pages/customer-service/shipping-policy.vue")
  },
  {
    name: "customized-jewelry___us",
    path: "/customized-jewelry",
    component: () => import("/opt/buildhome/repo/pages/customized-jewelry.vue")
  },
  {
    name: "customized-jewelry___in",
    path: "/in/customise-jewellery",
    component: () => import("/opt/buildhome/repo/pages/customized-jewelry.vue")
  },
  {
    name: "shop-all___us",
    path: "/diamond-jewelry/:start_with?",
    meta: indexlgrFVA7IwyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/diamond-jewelry/[[start_with]]/index.vue")
  },
  {
    name: "shop-all___in",
    path: "/in/diamond-jewellery/:start_with?",
    meta: indexlgrFVA7IwyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/diamond-jewelry/[[start_with]]/index.vue")
  },
  {
    name: "cyo-setting-list-earring___us",
    path: "/earring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93Jp7r7xDepbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/earring-settings/[[start_with]].vue")
  },
  {
    name: "cyo-setting-list-earring___in",
    path: "/in/earring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93Jp7r7xDepbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/earring-settings/[[start_with]].vue")
  },
  {
    name: "cyo-setting-detail-earring___us",
    path: "/earring-settings/:slug(.*-\\d+)?",
    meta: indexaIUetqf6MdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/earring-settings/[slug]/index.vue")
  },
  {
    name: "cyo-setting-detail-earring___in",
    path: "/in/earring-settings/:slug(.*-\\d+)?",
    meta: indexaIUetqf6MdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/earring-settings/[slug]/index.vue")
  },
  {
    name: "cyo-complete-earring___us",
    path: "/earrings/complete",
    meta: completeWH0nAi6to7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/earrings/complete.vue")
  },
  {
    name: "cyo-complete-earring___in",
    path: "/in/earrings/complete",
    meta: completeWH0nAi6to7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/earrings/complete.vue")
  },
  {
    name: "recently-purchase-all___us",
    path: "/engagement-ring-for-women",
    meta: engagement_45ring_45for_45women5TOxsekvq8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-for-women.vue")
  },
  {
    name: "recently-purchase-all___in",
    path: "/in/engagement-ring-for-women",
    meta: engagement_45ring_45for_45women5TOxsekvq8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-for-women.vue")
  },
  {
    name: "engagement-ring-guide-complete-the-ring___us",
    path: "/engagement-ring-guide/complete-the-ring",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/complete-the-ring.vue")
  },
  {
    name: "engagement-ring-guide-complete-the-ring___in",
    path: "/in/engagement-ring-guide/complete-the-ring",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/complete-the-ring.vue")
  },
  {
    name: "engagement-ring-guide___us",
    path: "/engagement-ring-guide",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/index.vue")
  },
  {
    name: "engagement-ring-guide___in",
    path: "/in/engagement-ring-guide",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/index.vue")
  },
  {
    name: "engagement-ring-guide-ring-cost___us",
    path: "/engagement-ring-guide/ring-cost",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/ring-cost.vue")
  },
  {
    name: "engagement-ring-guide-ring-cost___in",
    path: "/in/engagement-ring-guide/ring-cost",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/ring-cost.vue")
  },
  {
    name: "engagement-ring-guide-time-to-propose___us",
    path: "/engagement-ring-guide/time-to-propose",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/time-to-propose.vue")
  },
  {
    name: "engagement-ring-guide-time-to-propose___in",
    path: "/in/engagement-ring-guide/time-to-propose",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/time-to-propose.vue")
  },
  {
    name: "engagement-ring-guide-types-of-diamonds___us",
    path: "/engagement-ring-guide/types-of-diamonds",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/types-of-diamonds.vue")
  },
  {
    name: "engagement-ring-guide-types-of-diamonds___in",
    path: "/in/engagement-ring-guide/types-of-diamonds",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/types-of-diamonds.vue")
  },
  {
    name: "engagement-ring-guide-types-of-ring-settings___us",
    path: "/engagement-ring-guide/types-of-ring-settings",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/types-of-ring-settings.vue")
  },
  {
    name: "engagement-ring-guide-types-of-ring-settings___in",
    path: "/in/engagement-ring-guide/types-of-ring-settings",
    component: () => import("/opt/buildhome/repo/pages/engagement-ring-guide/types-of-ring-settings.vue")
  },
  {
    name: "cyo-complete-ring___us",
    path: "/engagement-ring/complete",
    meta: completeTU55VOp2ipMeta || {},
    component: () => import("/opt/buildhome/repo/pages/engagement-ring/complete.vue")
  },
  {
    name: "cyo-complete-ring___in",
    path: "/in/engagement-ring/complete",
    meta: completeTU55VOp2ipMeta || {},
    component: () => import("/opt/buildhome/repo/pages/engagement-ring/complete.vue")
  },
  {
    name: "engagement-rings___us",
    path: "/engagement-rings",
    component: () => import("/opt/buildhome/repo/pages/engagement-rings.vue")
  },
  {
    name: "engagement-rings___in",
    path: "/in/engagement-rings",
    component: () => import("/opt/buildhome/repo/pages/engagement-rings.vue")
  },
  {
    name: "ethical-and-conflict-free-diamonds___us",
    path: "/ethical-and-conflict-free-diamonds",
    component: () => import("/opt/buildhome/repo/pages/ethical-and-conflict-free-diamonds.vue")
  },
  {
    name: "ethical-and-conflict-free-diamonds___in",
    path: "/in/ethical-and-conflict-free-diamonds",
    component: () => import("/opt/buildhome/repo/pages/ethical-and-conflict-free-diamonds.vue")
  },
  {
    name: "fine-jewelry-new___us",
    path: "/fine-jewelry-new",
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry-new.vue")
  },
  {
    name: "fine-jewelry-new___in",
    path: "/in/fine-jewelry-new",
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry-new.vue")
  },
  {
    name: "shop-bangle-bracelet___us",
    path: "/fine-jewelry/bangle-bracelets",
    meta: bangle_45braceletspkaAX9mFLGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/bangle-bracelets.vue")
  },
  {
    name: "shop-bangle-bracelet___in",
    path: "/in/fine-jewellery/bangle-bracelets",
    meta: bangle_45braceletspkaAX9mFLGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/bangle-bracelets.vue")
  },
  {
    name: "shop-fashion-bracelet___us",
    path: "/fine-jewelry/fashion-bracelets",
    meta: fashion_45braceletsdPH5NqfcLsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/fashion-bracelets.vue")
  },
  {
    name: "shop-fashion-bracelet___in",
    path: "/in/fine-jewellery/fashion-bracelets",
    meta: fashion_45braceletsdPH5NqfcLsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/fashion-bracelets.vue")
  },
  {
    name: "shop-fashion-earring___us",
    path: "/fine-jewelry/fashion-earrings",
    meta: fashion_45earringsSzmOBXT1uNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/fashion-earrings.vue")
  },
  {
    name: "shop-fashion-earring___in",
    path: "/in/fine-jewellery/fashion-earrings",
    meta: fashion_45earringsSzmOBXT1uNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/fashion-earrings.vue")
  },
  {
    name: "shop-fashion-necklace___us",
    path: "/fine-jewelry/fashion-necklaces",
    meta: fashion_45necklacesh6fF0osaMrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/fashion-necklaces.vue")
  },
  {
    name: "shop-fashion-necklace___in",
    path: "/in/fine-jewellery/fashion-necklaces",
    meta: fashion_45necklacesh6fF0osaMrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/fashion-necklaces.vue")
  },
  {
    name: "shop-hoop___us",
    path: "/fine-jewelry/hoop-earrings",
    meta: hoop_45earringsMJndSuHzutMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/hoop-earrings.vue")
  },
  {
    name: "shop-hoop___in",
    path: "/in/fine-jewellery/hoop-earrings",
    meta: hoop_45earringsMJndSuHzutMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/hoop-earrings.vue")
  },
  {
    name: "fine-jewelry___us",
    path: "/fine-jewelry",
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/index.vue")
  },
  {
    name: "fine-jewelry___in",
    path: "/in/fine-jewellery",
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/index.vue")
  },
  {
    name: "shop-solitaire-necklace___us",
    path: "/fine-jewelry/solitaire-necklaces",
    meta: solitaire_45necklacesMlxJfymIi1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/solitaire-necklaces.vue")
  },
  {
    name: "shop-solitaire-necklace___in",
    path: "/in/fine-jewellery/solitaire-necklaces",
    meta: solitaire_45necklacesMlxJfymIi1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/solitaire-necklaces.vue")
  },
  {
    name: "shop-earring___us",
    path: "/fine-jewelry/stud-earrings",
    meta: stud_45earrings3ldcaDLOh7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/stud-earrings.vue")
  },
  {
    name: "shop-earring___in",
    path: "/in/fine-jewellery/stud-earrings",
    meta: stud_45earrings3ldcaDLOh7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/stud-earrings.vue")
  },
  {
    name: "shop-bracelet___us",
    path: "/fine-jewelry/tennis-bracelets",
    meta: tennis_45braceletsFVtShn9KEsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/tennis-bracelets.vue")
  },
  {
    name: "shop-bracelet___in",
    path: "/in/fine-jewellery/tennis-bracelets",
    meta: tennis_45braceletsFVtShn9KEsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/tennis-bracelets.vue")
  },
  {
    name: "shop-tennis-necklace___us",
    path: "/fine-jewelry/tennis-necklaces",
    meta: tennis_45necklaces6u7o4Lt4cLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/tennis-necklaces.vue")
  },
  {
    name: "shop-tennis-necklace___in",
    path: "/in/fine-jewellery/tennis-necklaces",
    meta: tennis_45necklaces6u7o4Lt4cLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/fine-jewelry/tennis-necklaces.vue")
  },
  {
    name: "forgot-password___us",
    path: "/forgot-password",
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___in",
    path: "/in/forgot-password",
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue")
  },
  {
    name: "franchise___us",
    path: "/franchise",
    meta: franchisevnnmWPzFD4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/franchise.vue")
  },
  {
    name: "franchise___in",
    path: "/in/franchise",
    meta: franchisevnnmWPzFD4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/franchise.vue")
  },
  {
    name: "free-product___us",
    path: "/free-product",
    component: () => import("/opt/buildhome/repo/pages/free-product.vue")
  },
  {
    name: "free-product___in",
    path: "/in/free-product",
    component: () => import("/opt/buildhome/repo/pages/free-product.vue")
  },
  {
    name: "friendly-diamond-difference___us",
    path: "/friendly-diamond-difference",
    component: () => import("/opt/buildhome/repo/pages/friendly-diamond-difference.vue")
  },
  {
    name: "friendly-diamond-difference___in",
    path: "/in/friendly-diamond-difference",
    component: () => import("/opt/buildhome/repo/pages/friendly-diamond-difference.vue")
  },
  {
    name: "green-and-sustainable-gift___us",
    path: "/green-and-sustainable-gift",
    component: () => import("/opt/buildhome/repo/pages/green-and-sustainable-gift.vue")
  },
  {
    name: "green-and-sustainable-gift___in",
    path: "/in/green-and-sustainable-gift",
    component: () => import("/opt/buildhome/repo/pages/green-and-sustainable-gift.vue")
  },
  {
    name: "shop-colored-diamond-rings___us",
    path: "/high-jewelry/colored-diamond-rings",
    meta: colored_45diamond_45rings7Cig1NUhF4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/colored-diamond-rings.vue")
  },
  {
    name: "shop-colored-diamond-rings___in",
    path: "/in/high-jewellery/colored-diamond-rings",
    meta: colored_45diamond_45rings7Cig1NUhF4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/colored-diamond-rings.vue")
  },
  {
    name: "hues-ring-landing___us",
    path: "/high-jewelry/hues-rings",
    meta: hues_45ringsZj9KjhAS8LMeta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/hues-rings.vue")
  },
  {
    name: "hues-ring-landing___in",
    path: "/in/high-jewellery/hues-rings",
    meta: hues_45ringsZj9KjhAS8LMeta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/hues-rings.vue")
  },
  {
    name: "high-jewelry-shop-all___us",
    path: "/high-jewelry",
    meta: indexcQFY4ZV22IMeta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/index.vue")
  },
  {
    name: "high-jewelry-shop-all___in",
    path: "/in/high-jewellery",
    meta: indexcQFY4ZV22IMeta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/index.vue")
  },
  {
    name: "shop-soiree-colored-jewelry___us",
    path: "/high-jewelry/soiree-colored-jewelry",
    meta: soiree_45colored_45jewelryyC96xpdhO3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/soiree-colored-jewelry.vue")
  },
  {
    name: "shop-soiree-colored-jewelry___in",
    path: "/in/high-jewellery/soiree-colored-jewellery",
    meta: soiree_45colored_45jewelryyC96xpdhO3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/high-jewelry/soiree-colored-jewelry.vue")
  },
  {
    name: "how-to-find-ring-size___us",
    path: "/how-to-find-ring-size",
    component: () => import("/opt/buildhome/repo/pages/how-to-find-ring-size.vue")
  },
  {
    name: "how-to-find-ring-size___in",
    path: "/in/how-to-find-ring-size",
    component: () => import("/opt/buildhome/repo/pages/how-to-find-ring-size.vue")
  },
  {
    name: "index___us",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___in",
    path: "/in",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "inspiration-slug___us",
    path: "/inspiration-:slug()",
    meta: indexLtJLE6PbXQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/inspiration-[slug]/index.vue")
  },
  {
    name: "inspiration-slug___in",
    path: "/in/inspiration-:slug()",
    meta: indexLtJLE6PbXQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/inspiration-[slug]/index.vue")
  },
  {
    name: "cyo-diamond-list-earring___us",
    path: "/lab-diamond-earrings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93q9gncYjmWDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-earrings/[[start_with]].vue")
  },
  {
    name: "cyo-diamond-list-earring___in",
    path: "/in/lab-diamond-earrings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93q9gncYjmWDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-earrings/[[start_with]].vue")
  },
  {
    name: "cyo-diamond-detail-earring___us",
    path: "/lab-diamond-earrings/:slug(.*-\\d+)?",
    meta: indexmBvYl92OaPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-earrings/[slug]/index.vue")
  },
  {
    name: "cyo-diamond-detail-earring___in",
    path: "/in/lab-diamond-earrings/:slug(.*-\\d+)?",
    meta: indexmBvYl92OaPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-earrings/[slug]/index.vue")
  },
  {
    name: "cyo-diamond-list-pendant___us",
    path: "/lab-diamond-pendants/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93KX5nKruSGiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-pendants/[[start_with]].vue")
  },
  {
    name: "cyo-diamond-list-pendant___in",
    path: "/in/lab-diamond-pendants/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93KX5nKruSGiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-pendants/[[start_with]].vue")
  },
  {
    name: "cyo-diamond-detail-pendant___us",
    path: "/lab-diamond-pendants/:slug(.*-\\d+)?",
    meta: indexhIkRGQCSFdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-pendants/[slug]/index.vue")
  },
  {
    name: "cyo-diamond-detail-pendant___in",
    path: "/in/lab-diamond-pendants/:slug(.*-\\d+)?",
    meta: indexhIkRGQCSFdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-pendants/[slug]/index.vue")
  },
  {
    name: "cyo-diamond-list-ring___us",
    path: "/lab-diamond-rings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93QY1lBgvY0zMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-rings/[[start_with]].vue")
  },
  {
    name: "cyo-diamond-list-ring___in",
    path: "/in/lab-diamond-rings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93QY1lBgvY0zMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-rings/[[start_with]].vue")
  },
  {
    name: "cyo-diamond-detail-ring___us",
    path: "/lab-diamond-rings/:slug(.*-\\d+)?",
    meta: indexfrE9jM0Z4OMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-rings/[slug]/index.vue")
  },
  {
    name: "cyo-diamond-detail-ring___in",
    path: "/in/lab-diamond-rings/:slug(.*-\\d+)?",
    meta: indexfrE9jM0Z4OMeta || {},
    component: () => import("/opt/buildhome/repo/pages/lab-diamond-rings/[slug]/index.vue")
  },
  {
    name: "lab-diamonds___us",
    path: "/lab-diamonds",
    component: () => import("/opt/buildhome/repo/pages/lab-diamonds.vue")
  },
  {
    name: "lab-diamonds___in",
    path: "/in/lab-diamonds",
    component: () => import("/opt/buildhome/repo/pages/lab-diamonds.vue")
  },
  {
    name: "light-performance-report___us",
    path: "/light-performance-report",
    component: () => import("/opt/buildhome/repo/pages/light-performance-report.vue")
  },
  {
    name: "light-performance-report___in",
    path: "/in/light-performance-report",
    component: () => import("/opt/buildhome/repo/pages/light-performance-report.vue")
  },
  {
    name: "login___us",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "login___in",
    path: "/in/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "loose-listing___us",
    path: "/loose-diamonds/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_936CKwf7RQbbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/loose-diamonds/[[start_with]].vue")
  },
  {
    name: "loose-listing___in",
    path: "/in/loose-diamonds/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_936CKwf7RQbbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/loose-diamonds/[[start_with]].vue")
  },
  {
    name: "loose-detail___us",
    path: "/loose-diamonds/:slug(.*-\\d+)?",
    meta: indexgsuu6onbmCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/loose-diamonds/[slug]/index.vue")
  },
  {
    name: "loose-detail___in",
    path: "/in/loose-diamonds/:slug(.*-\\d+)?",
    meta: indexgsuu6onbmCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/loose-diamonds/[slug]/index.vue")
  },
  {
    name: "our-share-of-care___us",
    path: "/our-share-of-care",
    component: () => import("/opt/buildhome/repo/pages/our-share-of-care.vue")
  },
  {
    name: "our-share-of-care___in",
    path: "/in/our-share-of-care",
    component: () => import("/opt/buildhome/repo/pages/our-share-of-care.vue")
  },
  {
    name: "our-story___us",
    path: "/our-story",
    component: () => import("/opt/buildhome/repo/pages/our-story.vue")
  },
  {
    name: "our-story___in",
    path: "/in/our-story",
    component: () => import("/opt/buildhome/repo/pages/our-story.vue")
  },
  {
    name: "cyo-setting-list-pendant___us",
    path: "/pendant-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_939QEojIU0fzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/pendant-settings/[[start_with]].vue")
  },
  {
    name: "cyo-setting-list-pendant___in",
    path: "/in/pendant-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_939QEojIU0fzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/pendant-settings/[[start_with]].vue")
  },
  {
    name: "cyo-setting-detail-pendant___us",
    path: "/pendant-settings/:slug(.*-\\d+)?",
    meta: indexMVtt49dTuqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/pendant-settings/[slug]/index.vue")
  },
  {
    name: "cyo-setting-detail-pendant___in",
    path: "/in/pendant-settings/:slug(.*-\\d+)?",
    meta: indexMVtt49dTuqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/pendant-settings/[slug]/index.vue")
  },
  {
    name: "cyo-complete-pendant___us",
    path: "/pendant/complete",
    meta: completeyAtcFsEG59Meta || {},
    component: () => import("/opt/buildhome/repo/pages/pendant/complete.vue")
  },
  {
    name: "cyo-complete-pendant___in",
    path: "/in/pendant/complete",
    meta: completeyAtcFsEG59Meta || {},
    component: () => import("/opt/buildhome/repo/pages/pendant/complete.vue")
  },
  {
    name: "preset-detail___us",
    path: "/preset-engagement-rings/:slug()",
    meta: indexke4KzZBi7xMeta || {},
    component: () => import("/opt/buildhome/repo/pages/preset-engagement-rings/[slug]/index.vue")
  },
  {
    name: "preset-detail___in",
    path: "/in/preset-engagement-rings/:slug()",
    meta: indexke4KzZBi7xMeta || {},
    component: () => import("/opt/buildhome/repo/pages/preset-engagement-rings/[slug]/index.vue")
  },
  {
    name: "preset-listing___us",
    path: "/preset-engagement-rings",
    meta: indexOUAfysWqiIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/preset-engagement-rings/index.vue")
  },
  {
    name: "preset-listing___in",
    path: "/in/preset-engagement-rings",
    meta: indexOUAfysWqiIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/preset-engagement-rings/index.vue")
  },
  {
    name: "press___us",
    path: "/press",
    component: () => import("/opt/buildhome/repo/pages/press.vue")
  },
  {
    name: "press___in",
    path: "/in/press",
    component: () => import("/opt/buildhome/repo/pages/press.vue")
  },
  {
    name: "privacy-policy___us",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___in",
    path: "/in/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue")
  },
  {
    name: "instock_earring___us",
    path: "/ready-to-ship-earrings",
    meta: index1NnWFUKxbrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ready-to-ship-earrings/index.vue")
  },
  {
    name: "instock_earring___in",
    path: "/in/ready-to-ship-earrings",
    meta: index1NnWFUKxbrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ready-to-ship-earrings/index.vue")
  },
  {
    name: "instock_ring___us",
    path: "/ready-to-ship-engagement-rings",
    meta: index05bnLK7ZwuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ready-to-ship-engagement-rings/index.vue")
  },
  {
    name: "instock_ring___in",
    path: "/in/ready-to-ship-engagement-rings",
    meta: index05bnLK7ZwuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ready-to-ship-engagement-rings/index.vue")
  },
  {
    name: "instock_pendant___us",
    path: "/ready-to-ship-pendants",
    meta: indexdreOrUYBCpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ready-to-ship-pendants/index.vue")
  },
  {
    name: "instock_pendant___in",
    path: "/in/ready-to-ship-pendants",
    meta: indexdreOrUYBCpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ready-to-ship-pendants/index.vue")
  },
  {
    name: "register___us",
    path: "/register",
    component: () => import("/opt/buildhome/repo/pages/register.vue")
  },
  {
    name: "register___in",
    path: "/in/register",
    component: () => import("/opt/buildhome/repo/pages/register.vue")
  },
  {
    name: "reset-password___us",
    path: "/reset-password",
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue")
  },
  {
    name: "reset-password___in",
    path: "/in/reset-password",
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue")
  },
  {
    name: "cyo-setting-list-ring___us",
    path: "/ring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93IYcmiW5ASAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ring-settings/[[start_with]].vue")
  },
  {
    name: "cyo-setting-list-ring___in",
    path: "/in/ring-settings/:start_with([a-zA-Z-]*[a-zA-Z])?",
    meta: _91_91start_with_93_93IYcmiW5ASAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ring-settings/[[start_with]].vue")
  },
  {
    name: "cyo-setting-detail-ring___us",
    path: "/ring-settings/:slug(.*-\\d+)?",
    meta: indexspMl1WkpyRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ring-settings/[slug]/index.vue")
  },
  {
    name: "cyo-setting-detail-ring___in",
    path: "/in/ring-settings/:slug(.*-\\d+)?",
    meta: indexspMl1WkpyRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/ring-settings/[slug]/index.vue")
  },
  {
    name: "search___us",
    path: "/search",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "search___in",
    path: "/in/search",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "sitemap___us",
    path: "/sitemap",
    component: () => import("/opt/buildhome/repo/pages/sitemap.vue")
  },
  {
    name: "sitemap___in",
    path: "/in/sitemap",
    component: () => import("/opt/buildhome/repo/pages/sitemap.vue")
  },
  {
    name: "terms-of-service___us",
    path: "/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service.vue")
  },
  {
    name: "terms-of-service___in",
    path: "/in/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service.vue")
  },
  {
    name: "testimonials-slug___us",
    path: "/testimonials/:slug()",
    component: () => import("/opt/buildhome/repo/pages/testimonials/[slug]/index.vue")
  },
  {
    name: "testimonials-slug___in",
    path: "/in/testimonials/:slug()",
    component: () => import("/opt/buildhome/repo/pages/testimonials/[slug]/index.vue")
  },
  {
    name: "testimonials___us",
    path: "/testimonials",
    component: () => import("/opt/buildhome/repo/pages/testimonials/index.vue")
  },
  {
    name: "testimonials___in",
    path: "/in/testimonials",
    component: () => import("/opt/buildhome/repo/pages/testimonials/index.vue")
  },
  {
    name: "toi-et-moi-ring___us",
    path: "/toi-et-moi-engagement-rings",
    meta: indexshj6ffwxrNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/toi-et-moi-engagement-rings/index.vue")
  },
  {
    name: "toi-et-moi-ring___in",
    path: "/in/toi-et-moi-engagement-rings",
    meta: indexshj6ffwxrNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/toi-et-moi-engagement-rings/index.vue")
  },
  {
    name: "valentines-day-jewelry___us",
    path: "/valentines-day-jewelry",
    meta: valentines_45day_45jewelryuYlb1yZkw9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/valentines-day-jewelry.vue")
  },
  {
    name: "valentines-day-jewelry___in",
    path: "/in/valentines-day-jewelry",
    meta: valentines_45day_45jewelryuYlb1yZkw9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/valentines-day-jewelry.vue")
  },
  {
    name: "shop-anniversary___us",
    path: "/wedding-rings/anniversary-rings",
    meta: anniversary_45ringsJcAwwr740cMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/anniversary-rings.vue")
  },
  {
    name: "shop-anniversary___in",
    path: "/in/wedding-rings/anniversary-rings",
    meta: anniversary_45ringsJcAwwr740cMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/anniversary-rings.vue")
  },
  {
    name: "shop-couple-rings___us",
    path: "/wedding-rings/couple-rings",
    meta: couple_45ringsyEeQd9ciVSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/couple-rings.vue")
  },
  {
    name: "shop-couple-rings___in",
    path: "/in/wedding-rings/couple-rings",
    meta: couple_45ringsyEeQd9ciVSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/couple-rings.vue")
  },
  {
    name: "shop-eternity___us",
    path: "/wedding-rings/eternity-rings",
    meta: eternity_45ringsBYVqspJJxGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/eternity-rings.vue")
  },
  {
    name: "shop-eternity___in",
    path: "/in/wedding-rings/eternity-rings",
    meta: eternity_45ringsBYVqspJJxGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/eternity-rings.vue")
  },
  {
    name: "wedding-rings___us",
    path: "/wedding-rings",
    meta: indexUQoAeaLy88Meta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/index.vue")
  },
  {
    name: "wedding-rings___in",
    path: "/in/wedding-rings",
    meta: indexUQoAeaLy88Meta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/index.vue")
  },
  {
    name: "shop-engagement-rings___us",
    path: "/wedding-rings/jewelry-engagement-rings",
    meta: jewelry_45engagement_45ringskW1jwE1gDhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/jewelry-engagement-rings.vue")
  },
  {
    name: "shop-engagement-rings___in",
    path: "/in/wedding-rings/jewelry-engagement-rings",
    meta: jewelry_45engagement_45ringskW1jwE1gDhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/jewelry-engagement-rings.vue")
  },
  {
    name: "shop-womens-wedding-rings___us",
    path: "/wedding-rings/womens-wedding-rings",
    meta: womens_45wedding_45ringsxLSSjPEdeTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/womens-wedding-rings.vue")
  },
  {
    name: "shop-womens-wedding-rings___in",
    path: "/in/wedding-rings/womens-wedding-rings",
    meta: womens_45wedding_45ringsxLSSjPEdeTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wedding-rings/womens-wedding-rings.vue")
  }
]